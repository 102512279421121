import { JIDO_SEISEI_FILE_STORAGE, DOWNLOAD_PERMISSION } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", { attrs: { "title": "\u30D5\u30A1\u30A4\u30EB\u306E\u5F62\u5F0F\u3092\u9078\u629E\u3057\u3066\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9", "visible": _vm.isVisible, "ok-text": "\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9", "cancel-text": "\u30AD\u30E3\u30F3\u30BB\u30EB", "after-close": _vm.afterModalClose, "ok-button-props": { props: { disabled: !_vm.canDownloadPermission } } }, on: { "cancel": _vm.handleCancel, "ok": _vm.handleOK } }, [_c("checkbox-input", { attrs: { "label": "PDF", "value": _vm.typesOfFile.length === 1 && _vm.typesOfFile.includes("pdf") }, on: { "change": function($event) {
    return _vm.chooseTypeOfFile("pdf", $event);
  } } }), _c("checkbox-input", { staticClass: "pt-3", attrs: { "value": _vm.typesOfFile.length === 1 && _vm.typesOfFile.includes("excel"), "label": "Excel" }, on: { "change": function($event) {
    return _vm.chooseTypeOfFile("excel", $event);
  } } }), _c("checkbox-input", { staticClass: "pt-3", attrs: { "value": _vm.typesOfFile.length === 2, "label": "PDF & Excel" }, on: { "change": function($event) {
    return _vm.chooseTypeOfFile("both", $event);
  } } })], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  name: "DownloadModal",
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      typesOfFile: [],
      page: JIDO_SEISEI_FILE_STORAGE,
      permissions: {
        download: DOWNLOAD_PERMISSION
      }
    };
  },
  computed: {
    canDownloadPermission() {
      return this.$can(this.permissions.download, this.page);
    }
  },
  methods: {
    chooseTypeOfFile(type, value) {
      this.typesOfFile = [];
      if (!value)
        return;
      if (type === "both") {
        this.typesOfFile = ["pdf", "excel"];
      } else {
        this.typesOfFile.push(type);
      }
    },
    handleCancel() {
      this.$emit("closed-modal", false);
      this.typesOfFile = [];
    },
    handleOK() {
      if (this.typesOfFile.length > 0) {
        if (this.typesOfFile.length > 1)
          this.$emit("download-click", "both");
        else
          this.$emit("download-click", this.typesOfFile);
        this.typesOfFile = [];
      }
    },
    afterModalClose() {
      this.typesOfFile = [];
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var DownloadModal = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { DownloadModal as D };
